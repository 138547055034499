<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">{{ title }}</h5>
      <div v-if="isEditing">
        <div :class="isSaving ? 'is-busy' : ''">
          <TextEditor v-model.trim="contentCopy"></TextEditor>
        </div>
        <SubmitButton
          @click="save"
          :text="'Save'"
          :textBusy="'Saving...'"
          :isLoading="isSaving"
          :disabled="isSaving"
          class="btn btn-primary mt-3"
        ></SubmitButton>
      </div>
      <div v-else>
        <div v-if="content && content !== ''" v-html="content"></div>
        <div v-else class="text-muted fst-italic">Not set</div>
        <div class="mt-3">
          <IconButton
            @click="isEditing = !isEditing"
            :icon="'edit'"
            :text="'Edit'"
            class="btn btn-primary me-2"
          ></IconButton>
          <IconButton
            @click="sendQuestionnaire"
            v-if="isQuestionnaire"
            :icon="'file-signature'"
            :text="'Send questionnaire'"
            :textBusy="'Sending...'"
            :isLoading="isSending"
            :disabled="isSending"
            class="btn btn-primary me-2"
          ></IconButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { httpPost } from '@/core/http';

export default {
  name: 'UserProfileNote',
  components: {
    IconButton: () => import('@/components/button/IconButton'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
    TextEditor: () => import('@/components/TextEditor'),
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
    metric: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    isQuestionnaire: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async save() {
      this.isSaving = true;
      try {
        const { metric, contentCopy } = this;
        const res = await httpPost(`/coaching/client/${this.client.userId}`, {
          metric,
          content: contentCopy,
        });
        this.isEditing = false;
        this.$emit('update', res.data);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isSaving = false;
      }
    },
    async sendQuestionnaire() {
      this.isSending = true;
      try {
        await httpPost(`/coaching/survey/${this.client.userId}`);
        this.$store.dispatch('addSystemMessage', 'Questionnaire sent.', { root: true });
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isSending = false;
      }
    },
  },
  data() {
    return {
      isEditing: false,
      isSaving: false,
      isSending: false,
      contentCopy: null,
    };
  },
  created() {
    this.contentCopy = this.content;
  },
};
</script>
